<template>
    <v-container>
    <v-layout justify-center align-center>
      <v-flex>
        <v-data-table
          dense
          :headers="headers"
          :items="computedList"
          :items-per-page="itemsPerPage"
          :loading="isLoading"
          loading-text="CARREGANDO..."
        >
          <template v-slot:top>
            <v-toolbar>
              <v-flex>
                <v-row>
                  <v-col cols="12" sm="4" md="6">
                    <v-text-field
                      prepend-icon="mdi-magnify"
                      label="Nome"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      prepend-icon="mdi-magnify"
                      label="CNPJ"
                      v-mask="'##.###.###/####-##'"
                      @keyup.enter="search"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="1" md="1">
                    <v-btn small fab>
                      <v-icon>mdi-reload</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-flex>
              <v-dialog v-model="dialog" max-width="800px">
                <!-- <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" v-on="on">
                    Criar
                  </v-btn>
                </template> -->
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="8" md="8">
                          <v-text-field
                            name="name"
                            label="Nome"
                            type="text"
                            :error-messages="nameErrors"
                            v-model.trim="$v.editedItem.name.$model"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-text-field
                            name="cnpj"
                            label="CNPJ"
                            type="text"
                            v-mask="'##.###.###/####-##'"
                            :error-messages="cnpjErrors"
                            v-model.trim="$v.editedItem.cnpj.$model"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-for="(media, index) in socialMedia"
                          :key="index"
                        >
                          <v-text-field
                            :name="media.toLowerCase()"
                            :label="media"
                            type="text"
                            :error-messages="
                              socialMediaErrors(media.toLowerCase())
                            "
                            v-model.trim="
                              $v.editedItem[media.toLowerCase()].$model
                            "
                          ></v-text-field>
                        </v-col>
                        <v-checkbox
                          v-model="finished"
                          @click="finishMistake(editedItem.mistake_id)"
                          label="Concluído"
                        ></v-checkbox>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="save"
                    >
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.facebook="{ item }">
            <v-tooltip v-if="item.facebook"  top>
              <template v-slot:activator="{ on }">
                <div v-on="on"> <v-icon> mdi-alert </v-icon></div>
              </template>
              <span>{{ item.notes_facebook }}</span>
            </v-tooltip>
              <template v-else>
                <v-icon> mdi-check </v-icon>
              </template>
          </template>

          <template v-slot:item.instagram="{ item }">
            <v-tooltip v-if="item.instagram"  top>
              <template v-slot:activator="{ on }">
                <div v-on="on"> <v-icon> mdi-alert </v-icon></div>
              </template>
              <span>{{ item.notes_instagram }}</span>
            </v-tooltip>
              <template v-else>
                <v-icon> mdi-check </v-icon>
              </template>
          </template>

          <template v-slot:item.linkedin="{ item }">
            <v-tooltip v-if="item.linkedin"  top>
              <template v-slot:activator="{ on }">
                <div v-on="on"> <v-icon> mdi-alert </v-icon></div>
              </template>
              <span>{{ item.notes_linkedin }}</span>
            </v-tooltip>
              <template v-else>
                <v-icon> mdi-check </v-icon>
              </template>
          </template>

          <template v-slot:item.youtube="{ item }">
            <v-tooltip v-if="item.youtube"  top>
              <template v-slot:activator="{ on }">
                <div v-on="on"> <v-icon> mdi-alert </v-icon></div>
              </template>
              <span>{{ item.notes_youtube }}</span>
            </v-tooltip>
              <template v-else>
                <v-icon> mdi-check </v-icon>
              </template>
          </template>

          <template v-slot:item.twitter="{ item }">
            <v-tooltip v-if="item.twitter"  top>
              <template v-slot:activator="{ on }">
                <div v-on="on"> <v-icon> mdi-alert </v-icon></div>
              </template>
              <span>{{ item.notes_twitter }}</span>
            </v-tooltip>
              <template v-else>
                <v-icon> mdi-check </v-icon>
              </template>
          </template>

          <template v-slot:item.individual="{ item }">
            <v-tooltip v-if="item.individual"  top>
              <template v-slot:activator="{ on }">
                <div v-on="on"> <v-icon> mdi-alert </v-icon></div>
              </template>
              <span>{{ item.notes_individual }}</span>
            </v-tooltip>
              <template v-else>
                <v-icon> mdi-check </v-icon>
              </template>
          </template>

          <template v-slot:item.other="{ item }">
            <v-tooltip v-if="item.other" top>
              <template v-slot:activator="{ on }">
                <div v-on="on"> <v-icon> mdi-alert </v-icon></div>
              </template>
              <span>{{ item.notes_other }}</span>
            </v-tooltip>
              <template v-else>
                <v-icon> mdi-check </v-icon>
              </template>
          </template>

          <template v-slot:item.site="{ item }">
            <v-tooltip v-if="item.site"  top>
              <template v-slot:activator="{ on }">
                <div v-on="on"> <v-icon> mdi-alert </v-icon></div>
              </template>
              <span>{{ item.notes_site }}</span>
            </v-tooltip>
              <template v-else>
                <v-icon> mdi-check </v-icon>
              </template>
          </template>

           <template v-slot:item.actions="{ item }">
            <v-icon class="space-icon" @click="editItem(item)"> mdi-pencil </v-icon>
            <v-icon @click="editItem(item)"> mdi-trash-can </v-icon>
          </template>

        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import api from '../../../plugins/api/ApiService';

const checkMistakes = (param, editedItem) => () => {
  return editedItem[`${param}_mistakes`] || false;
};
export default {
  props: ['datas'],

  data: () => ({
    isLoading: false,
    finished: false,
    itemsPerPage: 15,
    socialMedia: ['Twitter', 'Facebook', 'Instagram', 'Linkedin', 'Youtube', 'Site'],
    dialog: false,
    headers: [
      { text: 'Nome', value: 'name', width: '400' },
      { text: 'CNPJ', value: 'document' },
      { text: 'Facebook', value: 'facebook', sortable: false },
      { text: 'Instagram', value: 'instagram', sortable: false },
      { text: 'Linkedin', value: 'linkedin', sortable: false },
      { text: 'Twitter', value: 'twitter', sortable: false },
      { text: 'Youtube', value: 'youtube', sortable: false },
      { text: 'Site', value: 'site', sortable: false },
      { text: 'Individual', value: 'individual', sortable: false },
      { text: 'Outro', value: 'other', sortable: false },
      {
        text: 'Ações', value: 'actions', width: '120', align: 'center', sortable: false
      }
    ],
    editedIndex: -1,
    editedItem: {
      mistake_id: 0,
      name: '',
      cnpj: '',
      twitter: '',
      facebook: '',
      instagram: '',
      linkedin: '',
      youtube: '',
      site: '',
      individual: '',
      other: '',
      updated_at: '',
      facebook_mistakes: '',
      twitter_mistakes: '',
      linkedin_mistakes: '',
      instagram_mistakes: '',
      youtube_mistakes: '',
      site_mistakes: '',
      other_mistakes: '',
      individual_mistakes: '',
      notes_facebook: '',
      notes_twitter: '',
      notes_linkedin: '',
      notes_youtube: '',
      notes_instagram: '',
      notes_site: '',
      notes_other: '',
      notes_individual: ''
    },
    defaultItem: {
      mistake_id: 0,
      name: '',
      cnpj: '',
      twitter: '',
      facebook: '',
      instagram: '',
      linkedin: '',
      youtube: '',
      site: '',
      individual: '',
      other: '',
      updated_at: '',
      facebook_mistakes: '',
      twitter_mistakes: '',
      linkedin_mistakes: '',
      instagram_mistakes: '',
      youtube_mistakes: '',
      site_mistakes: '',
      other_mistakes: '',
      individual_mistakes: '',
      notes_facebook: '',
      notes_twitter: '',
      notes_linkedin: '',
      notes_youtube: '',
      notes_instagram: '',
      notes_site: '',
      notes_other: '',
      notes_individual: ''
    }
  }),

  computed: {
    computedList() {
      this.datas.map((data) => {
        data.document = this.formatCnpj(data.document);
      });
      return this.datas;
    },

    formTitle() {
      return this.editedIndex === -1 ? 'Criar' : 'Editar';
    },

    nameErrors() {
      const errors = [];
      const { name } = this.$v.editedItem;

      if (!name.$dirty) return errors;
      !name.required && errors.push('Insira o nome ou NA');
      !name.minlength && errors.push(`Insira no mínimo ${name.$params.minlength.min} caracteres!`);

      return errors;
    },

    cnpjErrors() {
      const errors = [];
      const { cnpj } = this.$v.editedItem;

      if (!cnpj.$dirty) return errors;
      !cnpj.required && errors.push('O CNPJ é obrigatório');
      !cnpj.minlength && errors.push('CNPJ inválido!');

      return errors;
    },

    // searchCpfError() {
    //   const errors = [];
    //   const cpf = this.$v.searchCpf;

    //   if (!cpf.$dirty) return errors;
    //   !cpf.minlength && errors.push('CPF inválido!');

    //   return errors;
    // }
  },

  validations() {
    return {
      editedItem: {
        name: { required, minlength: minLength(2) },
        cnpj: { required, minlength: minLength(14) },
        twitter: { required, checkMistakes: checkMistakes('twitter', this.editedItem) },
        facebook: {
          required, checkMistakes: checkMistakes('facebook', this.editedItem)
        },
        instagram: { required, checkMistakes: checkMistakes('instagram', this.editedItem) },
        linkedin: { required, checkMistakes: checkMistakes('linkedin', this.editedItem) },
        youtube: { required, checkMistakes: checkMistakes('youtube', this.editedItem) },
        site: { required, checkMistakes: checkMistakes('site', this.editedItem) },
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  methods: {
    formatCnpj(cnpj) {
      return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    },

    async editItem(item) {
      this.editedIndex = item.id;
      const { name } = item;
      await api.getInfoNameLegalEntity(name)
        .then(({ data }) => {
          this.editedItem = {
            ...data,
            mistake_id: item.id,
            cnpj: data.cnpj,
            facebook_mistakes: item.facebook,
            twitter_mistakes: item.twitter,
            linkedin_mistakes: item.linkedin,
            youtube_mistakes: item.youtube,
            instagram_mistakes: item.instagram,
            site_mistakes: item.site,
            other_mistakes: item.other,
            individual_mistakes: item.individual,
            notes_facebook: item.notes_facebook,
            notes_twitter: item.notes_twitter,
            notes_linkedin: item.notes_linkedin,
            notes_instagram: item.notes_instagram,
            notes_site: item.notes_site,
            notes_other: item.notes_other,
            notes_individual: item.notes_individual
          };
          this.dialog = true;
        }).catch((err) => {
          this.showSnackBarErrors = true;
          console.error(err);
        });
    },

    socialMediaErrors(media) {
      const errors = [];
      const mediaName = this.$v.editedItem[media];
      mediaName.checkMistakes && errors.push(this.editedItem[`notes_${media}`]);

      return errors;
    },

    async save() {
      if (this.editedIndex > -1) {
        await this.update();
      } else {
        await this.create();
      }
    },

    async update() {
      return api.updateLegalEntities(this.editedItem)
        .then(async (res) => {
          const itemUpdated = await { ...res.data };
          itemUpdated.cnpj = await this.formatCnpj(res.data.cnpj);
          itemUpdated.updated_at = await new Date(itemUpdated.updated_at).toLocaleDateString('pt-br');
          Object.assign(this.list[this.editedIndexCnpj], itemUpdated);
          this.snackBar = { show: true, color: 'success', text: 'Atualização realizada com sucesso!' };
          this.close();
        }).catch(({ response }) => {
          const errors = response.data.map((item) => item.options.message);
          this.snackBar = { show: true, color: 'error', text: errors.join(' ') };
        });
    },

    finishMistake(mistake_id) {
      this.$emit('updateMistake', mistake_id, this.finished);
    },

    close() {
      this.dialog = false;
      this.editedItem = { ...this.defaultItem };
      this.editedIndex = -1;
    }
  }
};
</script>

<style scoped>
  .space-icon{
    margin: 0 15px 0 20px;
  }
</style>
