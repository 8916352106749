<template>
  <div>
     <div v-if="showLoginComponent">
      <LoginBellatrix @loginEvent=loginBellatrix />
     </div>
      <v-card v-else>
      <v-tabs
        v-model="tab"
        centered
        dark
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          Pessoas Fisicas
        </v-tab>

        <v-tab href="#tab-2">
          Pessoas Jurídicas
        </v-tab>

      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :value="'tab-' + 1">
          <v-card flat>
            <v-card-text>
              <PrivatePersonMistakes @updateMistake=updateMistake :datas="privatePersonData" />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item :value="'tab-' + 2">
          <v-card flat>
            <v-card-text>
              <LegalEntitiesMistakes @updateMistake=updateMistake :datas="legalEntitiesData" />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import apiBellatrix from '../../../plugins/api/ApiBellatrix';
import LegalEntitiesMistakes from '../components/LegalEntitiesMistakes.vue';
import LoginBellatrix from '../components/LoginBellatrix.vue';
import PrivatePersonMistakes from '../components/PrivatePersonMistakes.vue';

export default {

  components: {
    LegalEntitiesMistakes,
    PrivatePersonMistakes,
    LoginBellatrix
  },

  computed: {
    privatePersonData() {
      return this.data.filter((data) => data.subject_type === 'private_person');
    },

    legalEntitiesData() {
      return this.data.filter((data) => data.subject_type === 'legal_entity');
    }
  },

  async created() {
    this.setTitle({ title: 'Erros' });
    if (this.verifyBellatrixTokenInLocalStorage()) {
      this.showLoginComponent = false;
      const { data } = await apiBellatrix.getMistakes();
      this.data = data;
    } else {
      this.showLoginComponent = true;
    }
  },

  methods: {
    ...mapActions(['setTitle']),

    async updateMistake(mistake_id, finished) {
      await apiBellatrix.updateMistake(mistake_id, finished);
    },

    verifyBellatrixTokenInLocalStorage() {
      const belatrixToken = localStorage.getItem('belatrix-token');
      return belatrixToken;
    },

    loginBellatrix(user) {
      apiBellatrix.login({ user }).then(({ headers }) => {
        localStorage.setItem('belatrix-token', headers.authorization);
      });
    }
  },

  data() {
    return {
      showLoginComponent: null,
      data: [],
      tab: null,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    };
  },
};
</script>
