import axios from 'axios';

const apiRequest = axios.create({
  baseURL: process.env.VUE_APP_BELLATRIX
});

const apiBellatrix = {

  login: (user) => {
    return apiRequest.post('/login', user);
  },

  getMistakes: () => {
    return apiRequest.get('/get_mistakes');
  },

  updateMistake: (mistake_id, solved) => {
    return apiRequest.put(`mistakes/${mistake_id}?solved=${solved}`);
  }
};

apiRequest.interceptors.request.use((config) => {
  return { ...config, headers: { ...config.headers, Authorization: localStorage.getItem('belatrix-token') } };
});

apiRequest.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem('belatrix-token');
  }
});

export default apiBellatrix;
